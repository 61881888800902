export function generatePassword({
  length = 16,
  includeSpecial = true,
  overrideSpecial = "!#$%&*()-_=+[]{}<>:?"
}: {
  length?: number;
  includeSpecial?: boolean;
  overrideSpecial?: string;
}): string {
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const digits = "0123456789";
  const special = overrideSpecial;
  
  // Build the character pool based on options
  let characterPool = lowercase + uppercase + digits;
  if (includeSpecial) {
    characterPool += special;
  }

  // Generate the password
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characterPool.length);
    password += characterPool[randomIndex];
  }

  return password;
}