/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
// import ReportProblemIcon from '@material-ui/icons/ReportProblem';
// import QueueIcon from '@material-ui/icons/Queue';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  DocsIcon,
  Sidebar,
  sidebarConfig,
  SidebarContext,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';

import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CategoryIcon from '@material-ui/icons/Category';
import ExploreIcon from '@material-ui/icons/Explore';
import MapIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/People';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';

// import LayersIcon from '@material-ui/icons/Layers';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 4 * sidebarConfig.logoHeight, // height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  subMenuColor: {
    background: '#73E7AB',
  }
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider style={{ background: '#164129' }} />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" title="Home" />
        <MyGroupsSidebarItem
          singularTitle="My Team"
          pluralTitle="My Teams"
          icon={GroupIcon}
        />

        <SidebarItem icon={CategoryIcon} to="catalog?filters[kind]=api" text="Catalog" title="Catálogo de software da RD" />
        
        {/* <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" title="Catálogo de software da RD">
          <SidebarSubmenu title="Catalog" >
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=domain"
              icon={DomainIcon}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={SystemIcon}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={ComponentIcon}
            />
            <SidebarSubmenuItem
              title="APIs"
              to="catalog?filters[kind]=api"
              icon={ApiIcon}
            />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource"
              icon={ResourceIcon}
            />
        
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Groups"
              to="catalog?filters[kind]=group"
              icon={GroupIcon}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user"
              icon={UserIcon}
            />
          </SidebarSubmenu>
        </SidebarItem> */}
        {/* <SidebarItem icon={DocsIcon} to="docs" text="Docs" /> */}
        <SidebarItem icon={ExploreIcon} to="explore" text="Explore" title="Explore o Dev Portal" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." title="Crie ou registre aplicações" />        
        {/* End global nav */}
        
        <SidebarDivider style={{ background: '#164129' }} />

        <SidebarItem icon={ImportContactsIcon} to="docs/default/system/techguides" text="Tech Guides" title="Veja as documentações das práticas e referências de tecnologia da RD" />
        {/* <SidebarItem icon={DocsIcon} to="docs/default/system/techguides" text="Data & Analytics Docs" title="Veja as documentações das práticas e referências de tecnologia da RD" style={{ width: "264px"}} /> */}
        
        {/* <SidebarDivider style={{ background: '#164129' }} /> */}

        <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" title="Radar de tecnologia da RD" />
        
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider style={{ background: '#164129' }} />
      <SidebarItem icon={DocsIcon} to="docs/default/system/devportal" text="Instructions" title="Instruções de uso do Dev Portal" />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings">
        <SidebarSettings />
      </SidebarGroup>
      
    </Sidebar>
    {children}
  </SidebarPage>
);
