import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// import { Entity } from '@backstage/catalog-model';
import {
  Select,
  SelectItem
} from '@backstage/core-components';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Consumer } from '../../types';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { pickEntityContext, isolateDomainName } from '../../utils';
import { generatePassword } from '../../passwordGen'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

const toTitleCase = (str:string):string => {
  return str.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}


const unKebab = (str: string):string => str.replace('-', ' ');

export const NewCredentialComponent = ({ environment, onChange }:{environment:string, onChange: (pair: any) => void}) => {
  const catalogApi                      = useApi(catalogApiRef);
  // const secretManaigerApi                = useApi(secretManagerApiRef);
  const classes                         = useStyles();

  const [domains, setDomains]           = useState<SelectItem[]>([]);
  const [consumer, setConsumer]         = useState<string>('');
  const [loading, setLoading]           = useState(false);
  const [consumerData, setConsumerData] = useState<Consumer | string>('');
  const [open, setOpen]                 = useState(false);
 
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const save = () => {
    if(consumerData && typeof consumerData === 'object'){
      const createPair = () => {
        const user     = `${consumerData.context}-${consumerData.domain}`;
        const password = generatePassword({
          length: 16,
          includeSpecial: true,
          overrideSpecial: "!#$%&*()-_=+[]{}<>:?"
        });

        const pair = {
          ...consumerData,
          user,
          password
        }
        onChange(pair);
      }
      createPair();
    }
    closeDialog();
  }

  const dialogContent = () => {
    return (
      <>
        <Typography>
          Para gerar, autorizar e diponibilizar uma credencial de API para um domínio consumir, você deve seleciona-lo na lista a seguir e clicar em "salvar".
        </Typography>
        <br />
    
          <Typography>
          ⚠️ A credencial será diponibilizada para as aplicações no diretório do respectivo dentro do cofre de senhas.
          </Typography>
       
        <Select
            // @ts-ignore TS2322
            onChange={setConsumer}
            placeholder="All results"
            label="Selecione o domínio consumidor"
            items={domains}
          />
      </>
    );
  };

  // Trigger as soon as it is mounted
  useEffect(() => {
    const fetchEntities = async () => {
      const entities = await catalogApi.getEntities({
        filter: { kind: 'Domain' },
      });
    
      return entities.items; // List of entities
    };
    // Fetch all domains
    // TODO: only fetch domains that have declarative dependency of 'current'
    const fetchDomainList = async () => {
      setLoading(true);
      const result = await fetchEntities();
      // Convert domain list to selecbox pattern
      const items = result.map(item => {
        const itemContext = toTitleCase(
          unKebab(
            pickEntityContext(item)
          )
        )
        return {
          label: `${itemContext} ${item.metadata.title}`,
          value: item.metadata.name
        }
      })
      // Trigger rendering
      setDomains(items);
      setLoading(false);
    };
    
    fetchDomainList();
  }, [catalogApi]);

  useEffect(() => {
    // Fetch selected domain metadata
    const fetchConsumerDomain = async () => {
     
      setLoading(true);
      if(!consumer.length){
        return false;
      }
   
      try {
        const result          = await catalogApi.getEntityByRef(`domain:default/${consumer}`);
        const consumerContext = result ? pickEntityContext(result) : '';
        const consumerDomain  = result ? isolateDomainName(result) : '';
        setConsumerData({
          environment,
          context: consumerContext,
          domain: consumerDomain
        })
        return setLoading(false);
      } catch (error) {
        // console.log('Error while fetching consumer entity data', error);
        return setLoading(false);
      }
    }


    fetchConsumerDomain();
  }, [consumer, catalogApi, environment])

  

  return (
    <>
      <Button color="primary" variant="contained" onClick={openDialog}>
        Adicionar
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Nova credencial
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{dialogContent()}</DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog}>
            Cancelar
          </Button>
          <Button color="primary" disabled={loading} onClick={save}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
