import { createApiRef } from "@backstage/core-plugin-api";

export const secretManagerApiRef = createApiRef<SecretManagerApi>({
  id: 'plugin.vault.service'
})

export interface SecretManagerApi {
  getConsumers(environment:string, context: string, domain: string): Promise<ConsumerList>;
  updateConsumers(environment:string, context: string, domain: string, secret: any):Promise<any>;
  getProviders(environment:string, context: string, domain: string): Promise<ProvidersList>;
  updateProviders(environment:string, context: string, domain: string, secret: any): Promise<any>;
}

export type ConsumerList = {
  consumers: any[]
}

export type ProvidersList = {
  providers: { [key: string]: string }
}

export type Consumer = {
  environment: string;
  context: string;
  domain: string;
  user?: string;
  password?: string;
}
