import React from 'react';
import { Route, Navigate } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderFieldExtensions, ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightIcon from '@material-ui/icons/WbSunny';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import {
  createTheme,
  palettes,
  genPageTheme,
  shapes,
} from '@backstage/theme';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import { ExplorePage } from '@backstage-community/plugin-explore';

import { TechDebtsPage, techdebtsPage } from './components/tech-debts/TechDebtsPage';

import {
  RELATION_API_CONSUMED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
} from '@backstage/catalog-model';

import { explorePage } from './components/explore/ExplorePage';
// import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { GetLoggedInUserField } from './scaffolder/GetLoggedInUser';
import { GitLabGroupPickerField } from './scaffolder/GitLabGroupPicker';
import { HttpPickerField } from './scaffolder/HttpPicker';
import { HttpPickerKeyField } from './scaffolder/HttpPickerKey';
import { HttpPickerSessionField } from './scaffolder/HttpPickerSession';
import { GetInputValidateField } from './scaffolder/GetInputValidate';
import { GitLabDomainPickerField } from './scaffolder/GitLabDomainPicker';
import { GetCurrentDatePickerField } from './scaffolder/GetActualDatePicker';
import { TagifyPage, tagifyPage } from './components/tagify/TagifyPage';
import { RdApiPage } from '@internal/backstage-plugin-rd-api';

const lightPalette = palettes.light;

lightPalette.bursts = {
  gradient: { linear: 'linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)' },
  backgroundColor: { default: '#7C3699' },
  slackChannelText: '#ddd',
  fontColor: '#FFFFFF'
}

lightPalette.banner = {  
    ...lightPalette.banner,
    error: '#D14343',
    text: '#333333',
    info: '#6ecb98',
    link: '#005034'
}

lightPalette.navigation = {
  ...lightPalette.navigation,
  navItem: { hoverBackground: '#356B4D' /* hoverBackground: '#929288',*/ },
  selectedColor: '#359562', // selectedColor: '#C6D4C8',
  indicator: '#359562', // indicator: '#C6D4C8',
  color: '#FFFFFF', // color: '#F4F4F4',
  background: '#005034'
}

const myTheme = createTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: '#29754D',
    },
    secondary: {
      main: '#C6D4C8',
    },
    error: {
      main: '#D14343',
    },
    warning: {
      main: '#FFB020',
    },
    info: {
      main: '#333333',
    },
    success: {
      main: '#00A925',
    },
    background: {
      default: '#F4F4F4',
      paper: '#F4F4F4',
    }
  },
  defaultPageTheme: 'home',
  fontFamily: 'Roboto, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#29754D', '#75A189'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#29754D', '#75A189'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#29754D', '#75A189'], shape: shapes.wave }),
    service: genPageTheme({
      colors: ['#29754D', '#75A189'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#29754D', '#75A189'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#29754D', '#75A189'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#29754D', '#75A189'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#29754D', '#75A189'], shape: shapes.wave }),
  },
});


const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'azure-auth-provider',
          title: 'Microsoft',
          message: 'Sign in using Microsoft',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'raiadrogasiltheme',
      title: 'RaiaDrogasil Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={myTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <GetLoggedInUserField />
        <GitLabGroupPickerField />
        <GitLabDomainPickerField />
        <HttpPickerField />
        <HttpPickerKeyField />
        <HttpPickerSessionField />
        <GetInputValidateField />
        <GetCurrentDatePickerField />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          id="raiadrogasiltechradar"
          title="RaiaDrogasil Tech Radar"
          pageTitle="RaiaDrogasil Tech Radar"
          subtitle="Escolha as tecnologias recomendadas para seus projetos"
          width={1500}
          height={800}
        />
      }
    />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            // selectedKinds: ['component', 'api', 'domain', 'system', 'group'],
            selectedKinds: ['component', 'api', 'domain', 'system', 'group'],
            selectedRelations: [
              // RELATION_OWNED_BY,
              // RELATION_OWNER_OF,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              // RELATION_PROVIDES_API,
              // RELATION_API_PROVIDED_BY,
              RELATION_PART_OF,
              RELATION_HAS_PART,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>

    <Route path="/tech-debts" element={<TechDebtsPage />}>
      { techdebtsPage }
    </Route>

    <Route path="/tagify" element={<TagifyPage />}>
      { tagifyPage }
    </Route>
    
    {/* alternative routes */}
    <Route path="/apis" >
      {/* <Navigate to="/catalog?filters[kind]=api" /> */}
      <Navigate to="/docs/default/system/techguides/api-management" />
    </Route>
    <Route path="/components" >
      <Navigate to="/catalog?filters[kind]=component" />
    </Route>
    <Route path="/domains" >
      <Navigate to="/catalog?filters[kind]=domain" />
    </Route>
    <Route path="/resources" >
      <Navigate to="/catalog?filters[kind]=resource" />
    </Route>
    <Route path="/systems" >
      <Navigate to="/catalog?filters[kind]=system" />
    </Route>
    <Route path="/teams" >
      <Navigate to="/catalog?filters[kind]=group" />
    </Route>
    <Route path="/users" >
      <Navigate to="/catalog?filters[kind]=user" />
    </Route>
    <Route path="/rd-api" element={<RdApiPage />} />
  </FlatRoutes>
);

const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
);

export default App;
