import { DiscoveryApi } from '@backstage/core-plugin-api'
import {ConsumerList, ProvidersList, SecretManagerApi} from '../types'

const allowList = [
  'api.devportal.raiadrogasil.io',
  'stage-api.devportal.raiadrogasil.io',
  'localhost:7007',
  'localhost:3000'
]

export class SecretManagerClient implements SecretManagerApi {
  private readonly discoveryApi: DiscoveryApi;
  constructor(options: {discoveryApi: DiscoveryApi}) {
    this.discoveryApi = options.discoveryApi;
  }
  async getConsumers(environment:string, context: string, domain: string): Promise<ConsumerList> {
    const url = `${await this.discoveryApi.getBaseUrl('vault')}`;
    const { host } = new URL(url);
    
    if(allowList.includes(host)){
      const response = await fetch(`${url}/instances/${encodeURIComponent(environment)}/secrets?namespace=${encodeURIComponent(context)}&mount=applications&path=${encodeURIComponent(domain)}/api/consumers`, {
        method: 'GET',
        redirect: 'manual'
      });
      
      if (response.status >= 400) {
        return {consumers: []};
      }
      const data = await response.json();
      const { secrets }  = data;
      return {
        consumers: Object.keys(secrets).map(key => {
          return {
            name: key,
            value: secrets[key]
          }
        })
      }
    }
    return {
      consumers: []
    }
  }

  async getProviders(environment:string, context: string, domain: string): Promise<ProvidersList> {
    const url = `${await this.discoveryApi.getBaseUrl('vault')}`;
    const { host } = new URL(url);
    if (allowList.includes(host)) {
      const response = await fetch(`${url}/instances/${environment}/secrets?namespace=${context}&mount=applications&path=${domain}/api/providers`, {
        method: 'GET'
      });
      if (response.status >= 500) {
        return {providers: {}};
      }
      const data = await response.json();
      return {
        providers: data.secrets || {}
      }
    }
    return {
      providers: {}
    };
  }

  async updateConsumers(environment:string, context: string, domain: string, secret: any): Promise<ConsumerList> {
    const url = `${await this.discoveryApi.getBaseUrl('vault')}`;
    const { host } = new URL(url);
    
    if (allowList.includes(host)) {
      
      const response = await fetch(`${url}/instances/${environment}/secrets/${domain}/api/consumers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Set content type to JSON
        },
        body: JSON.stringify({
          namespace: context,
          mount: 'applications',
          data: secret
        })
      });
      const data = await response.json();
      return data;
    }
    return {
      consumers: []
    }
  }

  async updateProviders(environment:string, context: string, domain: string, secret: any): Promise<ProvidersList> {
    const url = `${await this.discoveryApi.getBaseUrl('vault')}`;
    const { host } = new URL(url);
    if (allowList.includes(host)) {
      const response = await fetch(`${url}/instances/${environment}/secrets/${domain}/api/providers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Set content type to JSON
        },
        body: JSON.stringify({
          namespace: context,
          mount: 'applications',
          data: secret
        })
      });
      const data = await response.json();
      return data;
    }
    return {providers: {}};
  }
}
