import {
  createPlugin,
  createApiFactory,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { secretManagerApiRef } from './types';
import { SecretManagerClient } from './api/SecretManagerClient';

export const rdApiPlugin = createPlugin({
  id: 'rd-api',
  apis: [
    createApiFactory({
      api: secretManagerApiRef,
      deps: {
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi }) => new SecretManagerClient({ discoveryApi })
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const RdApiPage = rdApiPlugin.provide(
  createRoutableExtension({
    name: 'RdApiPage',
    component: () =>
      import('./components/ApiComponent').then(m => m.ApiComponent),
    mountPoint: rootRouteRef,
  }),
);
