import React from 'react';
import { useEffect, useState, useCallback } from 'react'
import { Typography, Grid } from '@material-ui/core';
import {
  InfoCard,
  Select,
} from '@backstage/core-components';
import { ExampleFetchComponent } from '../ExampleFetchComponent';
import { NewCredentialComponent } from '../NewCredentialComponent';

import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
// import { Entity } from '@backstage/catalog-model';
import { secretManagerApiRef, Consumer } from '../../types';
// import { catalogApiRef } from '@backstage/plugin-catalog-react';

import { pickEntityContext, isolateDomainName } from '../../utils';
// @ts-ignore TS2345
import bcrypt from 'bcryptjs';

const hash = (password:string) => {
  const salt = bcrypt.genSaltSync(10);
  return bcrypt.hashSync(password, salt);
}

const SELECT_ITEMS = [
  {
    label: 'DEV',
    value: 'dev'
  },
  {
    label: 'QA',
    value: 'qa'
  },
  {
    label: 'STG',
    value: 'stg'
  },
  {
    label: 'PROD',
    value: 'prod'
  },
]
type secretEntry = {
  name?: string;
  value?: string;
}
const updateSecretList = (secretList:secretEntry[], content:secretEntry) => {
  const list                           = [...secretList];
  const secret: {[key:string]: string} = {};
  list.push(content);
  list.forEach(item => {
    secret[item.name as string] = item.value as string;
  })
  return secret;
}

export const ApiComponent = () => {
  const DEFAULT_ENVIRONMENT           = "dev"
  const current                       = useEntity();
  const { entity }                    = current;
  const context                       = pickEntityContext(entity);
  const domain                        = isolateDomainName(entity);
  const [environment, setEnvironment] = useState(DEFAULT_ENVIRONMENT);
  
  const secretManagerApi              = useApi(secretManagerApiRef);

  const [loading, setLoading]         = useState(false);
  const [consumers, setConsumers]     = useState<any[]>([]);


  
  const fetchProviderConsumers        = useCallback(async (env:string, ctx:string, domainName:string) => {
    setLoading(true);
    const data = await secretManagerApi.getConsumers(env, ctx, domainName);
    setConsumers(data?.consumers);
    setLoading(false);
  }, [secretManagerApi]);

  const onChange = async (data:Consumer) => {
    const consumersCopy = consumers ? [...consumers] : [];
    // Append new consumer pair and format it to API call
    const secret = updateSecretList(
      consumersCopy, 
      {name: `${context}-${domain}-${data.user}`, value: hash(data.password || '')}
    );

    // Update storage with updated list of consumers
    secretManagerApi.updateConsumers(environment, context, domain, secret).then(() => {
      // Trigger ui list reload
      fetchProviderConsumers(environment, pickEntityContext(entity), isolateDomainName(entity));
    })

    // Fetch consumer providers
    const fetchConsumerProviders = await secretManagerApi.getProviders(environment, data.context, data.domain);
    const providerSecret         = fetchConsumerProviders?.providers || {};

    if(data.user){
      providerSecret[`${context}-${domain}-${data.user}` || '']    = data.password || '';
      secretManagerApi.updateProviders(environment, data.context, data.domain, providerSecret);
    }
  }

  useEffect(() => {
    fetchProviderConsumers(environment, pickEntityContext(entity), isolateDomainName(entity));
  }, [environment, entity, fetchProviderConsumers]);

  return (
    <>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <InfoCard title="Ambiente">
            <Typography variant="body1">
              Selecione o ambiente desejado para visualizar e gerenciar suas credenciais.
              {environment}
            </Typography>
            <Select
              onChange={(e) => setEnvironment(String(e))}
              label="Default"
              items={SELECT_ITEMS}
              selected={DEFAULT_ENVIRONMENT}
            />
            <NewCredentialComponent 
              environment={environment}
              onChange={onChange}
            />
          </InfoCard>
        </Grid>
        <Grid item>
          <ExampleFetchComponent loading={loading} consumers={consumers}/>
        </Grid>
      </Grid>
    </>
  );
}

