import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import { HomePageToolkit } from '@backstage/plugin-home';
import { FiGitlab, FiCodesandbox } from 'react-icons/fi';
import {
  SiSonarsource,
  SiOctopusdeploy,
  SiJirasoftware,
  SiDatadog,
} from 'react-icons/si';
import { MdDevices, MdSecurity } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { AiFillApi } from 'react-icons/ai';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const useStyles = makeStyles(() => ({
  imageIcon: {
    height: '100%',
  },
  iconRoot: {
    textAlign: 'center',
    color: '#29754D',
  },
}));

type ToolIconProps = {
  name: ReactElement;
};

const ToolIcon = (props: ToolIconProps) => {
  const { name } = props;
  const classes = useStyles();
  return <Icon children={name} classes={{ root: classes.iconRoot }} />;
};

const toolUrl = 'catalog/default/resource/';

const tools = [
  {
    // url: `${toolUrl}/gitlab`,
    url: 'https://gitlab.com/raiadrogasil/rd',
    label: 'GitLab',
    icon: <ToolIcon name={<FiGitlab />} />,
  },
  {
    // url: `${toolUrl}/argocd`,
    url: 'https://argocd.raiadrogasil.io/',
    label: 'Argo CD',
    icon: <ToolIcon name={<SiOctopusdeploy />} />,
  },
  {
    url: `${toolUrl}sonarqube`,
    label: 'SonarQube',
    icon: <ToolIcon name={<SiSonarsource />} />,
  },
  {
    url: `${toolUrl}saucelabs`,
    label: 'Device Farm',
    icon: <ToolIcon name={<MdDevices />} />,
  },
  {
    url: `${toolUrl}servicenow`,
    label: 'ServiceNow',
    icon: <ToolIcon name={<RiCustomerService2Line />} />,
  },
  {
    // url: `${toolUrl}jira`,
    url: 'https://raiadrogasilsa.atlassian.net/jira',
    label: 'Jira',
    icon: <ToolIcon name={<SiJirasoftware />} />,
  },
  {
    // url: `${toolUrl}datadog`,
    url: 'https://app.datadoghq.com/account/login/id/5e966784-cce5-11ed-af81-da7ad0900002',
    label: 'Datadog',
    icon: <ToolIcon name={<SiDatadog />} />,
  },
  {
    url: '/docs/default/system/techguides/api-management/',
    label: 'API Portal',
    icon: <ToolIcon name={<AiFillApi />} />,
  },
  {
    url: `${toolUrl}appsec-flow`,
    label: 'AppSec Flow',
    icon: <ToolIcon name={<MdSecurity />} />,
  },
  {
    url: `${toolUrl}sandbox`,
    label: 'Sandbox',
    icon: <ToolIcon name={<FiCodesandbox />} />,
  },
  {
    icon: <LocalOfferIcon style={{ color: '#005034' }} />,
    label: `Tagify`,
    url: `tagify`
  }
];

export const Toolkit = () => {
  return <HomePageToolkit title="Essential Toolkit" tools={tools} />;
};
