import React from 'react';

type ConsumerEntry = {
  name: string,
  value: string
}

import {
  Table,
  TableColumn,
  Progress,
  // ResponseErrorPanel,
} from '@backstage/core-components';


export const DenseTable = ({ consumers }:{ consumers: ConsumerEntry[] }) => {
  const columns: TableColumn[] = [

    { title: 'Name', field: 'name' },
    { title: 'Value', field: 'value' },
    { title: 'Actions', field: 'actions' },
  ];

  const data = consumers.length ? consumers.map((consumer:ConsumerEntry) => {
    return {
      name: consumer.name,
      value: "**************",
      actions: 'Actions',
    };
  }) : [];

  return (
    <Table
      title="Credenciais para o domínio"
      options={{ search: false, paging: false }}
      columns={columns}
      data={data}
    />
  );
};

export const ExampleFetchComponent = ({ consumers, loading }:{ consumers: ConsumerEntry[], loading: boolean }) => {
  if (loading) {
    return <Progress />;
  }

  return <DenseTable consumers={consumers || []} />;
};
